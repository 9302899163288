import UAE from "../../assets/images/Perps/UAE.png";
import { Chart } from "./Chart";
import {
  SelectionGroup,
  SelectionItem,
} from "../../Components/Selection/Selection";
import {
  ArrowToDownHeader,
  ArrowToLeft,
  CloseIcon,
  LightIcon,
  NibiruIcon,
  NUSDIcon,
} from "../../AssetComponents/Images";
import InputSlider from "react-input-slider";
import { PureComponent, useState } from "react";
import { BlackButton } from "../../Components/Buttons/BlackButton";
import Modal from "react-responsive-modal";
import { PurpleButton } from "../../Components/Buttons/PurpleButton";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Toggle } from "../../Components/Toggle/Toggle";
import { GreenButton } from "../../Components/Buttons/GreenButton";
import { useNavigate } from "react-router-dom";

export const Deposit = () => {
  const [leverage, setLeverage] = useState(3);
  const [amount, setAmount] = useState(0);
  const navigate = useNavigate();
  const [price, setPrice] = useState(0);

  const [showModal, setShowModal] = useState(false);

  const [tab, setTab] = useState(0);
  const [longShort, setLongShort] = useState(0);
  const [mode, setMode] = useState(0);

  const [modalFlag, setModalFlag] = useState(0);

  const [value, setValue] = useState(0.1);

  // Your label values
  const labels = [0.1, 1, 2, 5, 10, 25, 50, 100];

  // Mapping values to slider values
  const logScale = (val) => Math.log(val);
  const revLogScale = (val) => Math.exp(val);
  const handleChange = (val) => {
    const newValue = revLogScale(val).toFixed(1);
    setValue(newValue);
  };

  return (
    <div className="w-full h-[calc(100vh-100px)] overflow-auto">
      <div className="w-[80vw] mx-auto my-[20px]">
        <div
          onClick={() => navigate(-1)}
          className="w-full cursor-pointer flex gap-2 rounded-[8px] px-2 py-3 hover:bg-[#f6f6f6] bg-white shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]"
        >
          <ArrowToLeft />
          <div>Back to Market Page</div>
        </div>

        <div className="w-full bg-white mt-3 p-[16px] rounded-[8px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
          <div className="w-full grid grid-cols-4">
            <div className="col-span-2">
              <div className="bg-[#E8FFF2] w-max text-[#38A569] text-[14px] px-2 rounded-sm">
                68.11% APY
              </div>
              <div className="flex items-center">
                <div className="font-medium text-[18px]">gNUSD Vault</div>
                <svg
                  width="46"
                  height="47"
                  viewBox="0 0 46 47"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_dd_2655_12436)">
                    <rect
                      x="11"
                      y="11.5"
                      width="24"
                      height="24"
                      rx="12"
                      fill="#5B1DEE"
                    />
                    <rect
                      x="11"
                      y="11.5"
                      width="24"
                      height="24"
                      rx="12"
                      fill="url(#paint0_linear_2655_12436)"
                      fill-opacity="0.18"
                    />
                    <g filter="url(#filter1_d_2655_12436)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.6016 26.5V22.96C17.6016 22.1398 17.9744 21.364 18.6149 20.8516L21.3149 18.6916C22.301 17.9028 23.7022 17.9028 24.6882 18.6916L27.3882 20.8516C28.0287 21.364 28.4016 22.1398 28.4016 22.96V26.5C28.4016 27.8255 27.327 28.9 26.0016 28.9H20.0016C18.6761 28.9 17.6016 27.8255 17.6016 26.5Z"
                        fill="white"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.6016 26.5V22.96C17.6016 22.1398 17.9744 21.364 18.6149 20.8516L21.3149 18.6916C22.301 17.9028 23.7022 17.9028 24.6882 18.6916L27.3882 20.8516C28.0287 21.364 28.4016 22.1398 28.4016 22.96V26.5C28.4016 27.8255 27.327 28.9 26.0016 28.9H20.0016C18.6761 28.9 17.6016 27.8255 17.6016 26.5Z"
                        stroke="#202020"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <path
                      d="M20.6016 25.3L22.1016 23.8L23.6016 25.3L25.4016 23.5"
                      stroke="#202020"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_dd_2655_12436"
                      x="0.333331"
                      y="0.833331"
                      width="45.3333"
                      height="45.3333"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="2.66667" dy="2.66667" />
                      <feGaussianBlur stdDeviation="4" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.733333 0 0 0 0 0.764706 0 0 0 0 0.807843 0 0 0 0.6 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2655_12436"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="-2.66667" dy="-2.66667" />
                      <feGaussianBlur stdDeviation="4" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.992157 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_2655_12436"
                        result="effect2_dropShadow_2655_12436"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_2655_12436"
                        result="shape"
                      />
                    </filter>
                    <filter
                      id="filter1_d_2655_12436"
                      x="16.8516"
                      y="17.35"
                      width="13.3008"
                      height="13.3"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="1" dy="1" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.12549 0 0 0 0 0.12549 0 0 0 0 0.12549 0 0 0 1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2655_12436"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2655_12436"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_2655_12436"
                      x1="23"
                      y1="11.5"
                      x2="23"
                      y2="27"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="white" />
                      <stop offset="1" stop-color="white" stop-opacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
            <div className="col-span-2 flex items-start justify-between">
              <div>
                <div>Epoch 8</div>
                <div className="flex gap-2 text-[14px]">
                  <span className="text-[#5A5A5A]">START</span>
                  <span>7/23/24, 7:08 PM</span>
                  <span className="text-[#5A5A5A]">END</span>
                  <span>7/23/24, 7:08 PM</span>
                </div>
              </div>
              <div className="text-[14px] border-[#E3E3E3] border-[1px] bg-[#F6F6F6] rounded-md px-2">
                Force new Epoch
              </div>
            </div>
            <div>
              <div className="text-[#5a5a5a]">TVL</div>
              <div className="flex items-center gap-2">
                <div>1,232,231</div>
                <NUSDIcon />
              </div>
            </div>
            <div>
              <div className="text-[#5a5a5a]">Collat Ratio</div>
              <div className="flex items-center gap-2">
                <div>90.88%</div>
              </div>
            </div>
            <div>
              <div className="text-[#5a5a5a]">gUSD Price</div>
              <div className="flex items-center gap-2">
                <div>1.00155</div>
                <NUSDIcon />
              </div>
            </div>
            <div>
              <div className="text-[#5a5a5a]">gUSD Supply</div>
              <div className="flex items-center gap-2">
                <div>1.00155</div>
                <svg
                  className="w-[20px]"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2655_12310)">
                    <path
                      d="M23.7188 12C23.7188 18.4721 18.4721 23.7188 12 23.7188C5.52791 23.7188 0.28125 18.4721 0.28125 12C0.28125 5.52791 5.52791 0.28125 12 0.28125C18.4721 0.28125 23.7188 5.52791 23.7188 12Z"
                      fill="#5B1DEE"
                    />
                    <path
                      d="M23.7188 12C23.7188 18.4721 18.4721 23.7188 12 23.7188C5.52791 23.7188 0.28125 18.4721 0.28125 12C0.28125 5.52791 5.52791 0.28125 12 0.28125C18.4721 0.28125 23.7188 5.52791 23.7188 12Z"
                      fill="url(#paint0_linear_2655_12310)"
                      fill-opacity="0.18"
                    />
                    <path
                      opacity="0.1"
                      d="M6.81641 6.21398L12.3609 1.59375L17.9053 6.21398H6.81641Z"
                      fill="white"
                    />
                    <path
                      opacity="0.1"
                      d="M17.9053 6.21411L12.3609 10.8346L6.81641 6.21411H17.9053Z"
                      fill="white"
                    />
                    <path
                      opacity="0.1"
                      d="M17.9038 15.4548L12.3594 10.8346L17.9038 6.21411V15.4548Z"
                      fill="white"
                    />
                    <path
                      d="M9.40493 6.86462C9.48462 6.61894 9.56431 6.37348 9.64428 6.12733C9.43428 5.97465 9.22472 5.82244 9.01562 5.67046C9.01609 5.66929 9.01609 5.66906 9.01633 5.66883C9.01656 5.66859 9.0168 5.66836 9.01703 5.66836C9.01772 5.66813 9.01843 5.66813 9.01912 5.66813C9.27647 5.66813 9.53384 5.6679 9.79137 5.6679C9.79165 5.6679 9.79212 5.66766 9.7939 5.66696C9.87321 5.42268 9.9529 5.17745 10.0324 4.93224C10.0333 4.93224 10.0335 4.93201 10.034 4.93201C10.0343 4.93201 10.0347 4.93201 10.0349 4.93224C10.0354 4.93317 10.0359 4.93411 10.0363 4.93503C10.1157 5.17886 10.1949 5.42244 10.2744 5.66627C10.2744 5.6665 10.2748 5.66673 10.2755 5.6679C10.5338 5.6679 10.7928 5.6679 11.0515 5.6679C11.0519 5.66906 11.0519 5.66929 11.0519 5.66976C11.0519 5.66999 11.0517 5.67046 11.0515 5.67069C10.8427 5.82267 10.6338 5.97442 10.4238 6.12733C10.4431 6.18863 10.4634 6.24994 10.4834 6.31125C10.5035 6.37279 10.5233 6.43432 10.5433 6.49586C10.5634 6.5574 10.5835 6.61894 10.6032 6.68047C10.6233 6.74178 10.6431 6.80331 10.6613 6.86601C10.4524 6.71427 10.2438 6.56253 10.0343 6.41055C9.82521 6.56253 9.61634 6.71427 9.40746 6.86601C9.40634 6.86555 9.40559 6.86509 9.40493 6.86462Z"
                      fill="#EFEFEF"
                    />
                    <path
                      d="M15.6123 16.3177L13.2282 18.2273L9.95825 20.8466L7.77344 19.026L8.75664 18.2273L13.3881 14.4639L15.6123 16.3177Z"
                      fill="white"
                    />
                    <path
                      d="M17.9065 18.2265L12.3623 22.847L9.96094 20.8458L13.2308 18.2265L15.6149 16.3169L17.9065 18.2265Z"
                      fill="#F5F5F5"
                    />
                    <path
                      d="M13.3893 14.4639L8.75788 18.2273L7.77467 19.026L6.81641 18.2273L12.3608 13.6068L13.3893 14.4639Z"
                      fill="#F5F5F5"
                    />
                    <path
                      d="M9.0003 10.8066L6.81641 12.5045V8.98657L9.0003 10.8066Z"
                      fill="#F5F5F5"
                    />
                    <path
                      d="M11.1702 12.614L6.81641 16.1902V12.5041L9.0003 10.8062L11.1702 12.614Z"
                      fill="white"
                    />
                    <path
                      d="M12.3608 13.6061L6.81641 18.2266V16.19L11.1702 12.6138L12.3608 13.6061Z"
                      fill="#F5F5F5"
                    />
                    <path
                      d="M11.4674 5.08643C11.5472 4.84074 11.6268 4.59529 11.7068 4.34914C11.4968 4.19646 11.2872 4.04425 11.0781 3.89227C11.0786 3.8911 11.0786 3.89087 11.0788 3.89064C11.0791 3.8904 11.0792 3.89017 11.0795 3.89017C11.0802 3.88993 11.0809 3.88993 11.0816 3.88993C11.3389 3.88993 11.5963 3.8897 11.8539 3.8897C11.8541 3.8897 11.8546 3.88946 11.8564 3.88877C11.9357 3.64449 12.0154 3.39926 12.0949 3.15404C12.0959 3.15404 12.0961 3.15381 12.0965 3.15381C12.0968 3.15381 12.0972 3.15381 12.0975 3.15404C12.0979 3.15498 12.0984 3.15591 12.0989 3.15685C12.1781 3.40066 12.2574 3.64425 12.3368 3.88807C12.3368 3.8883 12.3373 3.88854 12.338 3.8897C12.5963 3.8897 12.8552 3.8897 13.114 3.8897C13.1145 3.89087 13.1145 3.8911 13.1145 3.89156C13.1145 3.8918 13.1143 3.89227 13.114 3.8925C12.9051 4.04448 12.6962 4.19622 12.4862 4.34914C12.5057 4.41044 12.5259 4.47175 12.546 4.53305C12.566 4.59459 12.5858 4.65612 12.6059 4.71766C12.6259 4.7792 12.6459 4.84074 12.6658 4.90227C12.6858 4.96359 12.7056 5.02512 12.7238 5.08782C12.5149 4.93608 12.3063 4.78433 12.0968 4.63235C11.8877 4.78433 11.6788 4.93608 11.47 5.08782C11.4688 5.08712 11.4681 5.0869 11.4674 5.08643Z"
                      fill="#EFEFEF"
                    />
                    <path
                      d="M11.4674 8.67236C11.5472 8.42667 11.6268 8.18122 11.7068 7.93507C11.4968 7.78239 11.2872 7.63017 11.0781 7.4782C11.0786 7.47703 11.0786 7.4768 11.0788 7.47656C11.0791 7.47633 11.0792 7.4761 11.0795 7.4761C11.0802 7.47586 11.0809 7.47586 11.0816 7.47586C11.3389 7.47586 11.5963 7.47564 11.8539 7.47564C11.8541 7.47564 11.8546 7.4754 11.8564 7.4747C11.9357 7.23041 12.0154 6.98519 12.0949 6.73997C12.0959 6.73997 12.0961 6.73975 12.0965 6.73975C12.0968 6.73975 12.0972 6.73975 12.0975 6.73997C12.0979 6.74091 12.0984 6.74184 12.0989 6.74277C12.1781 6.98659 12.2574 7.23018 12.3368 7.474C12.3368 7.47423 12.3373 7.47447 12.338 7.47564C12.5963 7.47564 12.8552 7.47564 13.114 7.47564C13.1145 7.4768 13.1145 7.47703 13.1145 7.47749C13.1145 7.47773 13.1143 7.4782 13.114 7.47843C12.9051 7.63041 12.6962 7.78215 12.4862 7.93507C12.5057 7.99637 12.5259 8.05767 12.546 8.11899C12.566 8.18052 12.5858 8.24206 12.6059 8.3036C12.6259 8.36514 12.6459 8.42667 12.6658 8.48821C12.6858 8.54952 12.7056 8.61105 12.7238 8.67375C12.5149 8.52201 12.3063 8.37027 12.0968 8.21828C11.8877 8.37027 11.6788 8.52201 11.47 8.67375C11.4688 8.67306 11.4681 8.67259 11.4674 8.67236Z"
                      fill="#EFEFEF"
                    />
                    <path
                      d="M13.5182 6.86462C13.5979 6.61894 13.6776 6.37348 13.7576 6.12733C13.5476 5.97465 13.338 5.82244 13.1289 5.67046C13.1294 5.66929 13.1294 5.66906 13.1297 5.66883C13.1298 5.66859 13.13 5.66836 13.1303 5.66836C13.131 5.66813 13.1317 5.66813 13.1324 5.66813C13.3897 5.66813 13.6471 5.6679 13.9047 5.6679C13.9049 5.6679 13.9053 5.66766 13.9072 5.66696C13.9864 5.42268 14.0662 5.17745 14.1457 4.93224C14.1467 4.93224 14.1468 4.93201 14.1473 4.93201C14.1476 4.93201 14.1481 4.93201 14.1483 4.93224C14.1487 4.93317 14.1492 4.93411 14.1497 4.93503C14.2289 5.17886 14.3082 5.42244 14.3876 5.66627C14.3876 5.6665 14.3881 5.66673 14.3888 5.6679C14.6471 5.6679 14.906 5.6679 15.1648 5.6679C15.1653 5.66906 15.1652 5.66929 15.1652 5.66976C15.1652 5.66999 15.1651 5.67046 15.1648 5.67069C14.9559 5.82267 14.747 5.97442 14.537 6.12733C14.5564 6.18863 14.5767 6.24994 14.5967 6.31125C14.6168 6.37279 14.6366 6.43432 14.6567 6.49586C14.6767 6.5574 14.6967 6.61894 14.7166 6.68047C14.7366 6.74178 14.7564 6.80331 14.7746 6.86601C14.5657 6.71427 14.3571 6.56253 14.1476 6.41055C13.9384 6.56253 13.7296 6.71427 13.5208 6.86601C13.5196 6.86555 13.5189 6.86509 13.5182 6.86462Z"
                      fill="#EFEFEF"
                    />
                    <path
                      d="M13.5182 10.376C13.5979 10.1303 13.6776 9.88482 13.7576 9.63873C13.5476 9.48601 13.338 9.3338 13.1289 9.18182C13.1294 9.18066 13.1294 9.18042 13.1297 9.18019C13.1298 9.17995 13.13 9.17972 13.1303 9.17972C13.131 9.17948 13.1317 9.17948 13.1324 9.17948C13.3897 9.17948 13.6471 9.17925 13.9047 9.17925C13.9049 9.17925 13.9053 9.17902 13.9072 9.17832C13.9864 8.93404 14.0662 8.68882 14.1457 8.44359C14.1467 8.44359 14.1468 8.44336 14.1473 8.44336C14.1476 8.44336 14.1481 8.44336 14.1483 8.44359C14.1487 8.44453 14.1492 8.44546 14.1497 8.4464C14.2289 8.69021 14.3082 8.9338 14.3876 9.17762C14.3876 9.17785 14.3881 9.17809 14.3888 9.17925C14.6471 9.17925 14.906 9.17925 15.1648 9.17925C15.1653 9.18042 15.1652 9.18066 15.1652 9.18112C15.1652 9.18135 15.1651 9.18182 15.1648 9.18205C14.9559 9.33403 14.747 9.48582 14.537 9.63873C14.5564 9.69995 14.5767 9.76126 14.5967 9.82257C14.6168 9.88417 14.6366 9.94567 14.6567 10.0073C14.6767 10.0688 14.6967 10.1303 14.7166 10.1919C14.7366 10.2532 14.7564 10.3147 14.7746 10.3774C14.5657 10.2256 14.3571 10.0739 14.1476 9.92195C13.9384 10.0739 13.7296 10.2256 13.5208 10.3774C13.5196 10.3766 13.5189 10.3764 13.5182 10.376Z"
                      fill="#EFEFEF"
                    />
                    <path
                      d="M15.6315 8.61524C15.7112 8.36955 15.7909 8.1241 15.8709 7.87795C15.6609 7.72527 15.4513 7.57305 15.2422 7.42108C15.2427 7.41991 15.2427 7.41968 15.2429 7.41945C15.2431 7.41921 15.2434 7.41898 15.2436 7.41898C15.2443 7.41874 15.245 7.41874 15.2457 7.41874C15.5031 7.41874 15.7604 7.41851 16.018 7.41851C16.0182 7.41851 16.0186 7.41827 16.0205 7.41758C16.0998 7.1733 16.1795 6.92807 16.259 6.68285C16.2599 6.68285 16.2601 6.68262 16.2606 6.68262C16.2609 6.68262 16.2613 6.68262 16.2615 6.68285C16.262 6.68379 16.2625 6.68472 16.2629 6.68565C16.3422 6.92947 16.4215 7.17306 16.501 7.41688C16.501 7.41711 16.5014 7.41735 16.5021 7.41851C16.7604 7.41851 17.0194 7.41851 17.2781 7.41851C17.2785 7.41968 17.2785 7.41991 17.2785 7.42037C17.2785 7.42061 17.2783 7.42108 17.2781 7.42131C17.0693 7.57329 16.8604 7.72503 16.6504 7.87795C16.6697 7.93925 16.69 8.00055 16.71 8.06186C16.7301 8.1234 16.7499 8.18493 16.7699 8.24647C16.79 8.30801 16.8101 8.36955 16.8298 8.43109C16.8499 8.4924 16.8697 8.55393 16.8879 8.61663C16.679 8.46489 16.4704 8.31314 16.2609 8.16116C16.0518 8.31314 15.8429 8.46489 15.6341 8.61663C15.6327 8.61616 15.6322 8.6157 15.6315 8.61524Z"
                      fill="#EFEFEF"
                    />
                    <path
                      d="M15.5533 12.1586C15.633 11.9129 15.7128 11.6675 15.7927 11.4214C15.5827 11.2687 15.3731 11.1164 15.1641 10.9644C15.1645 10.9633 15.1645 10.963 15.1647 10.9629C15.165 10.9626 15.1652 10.9624 15.1655 10.9624C15.1661 10.9621 15.1668 10.9621 15.1675 10.9621C15.4249 10.9621 15.6822 10.9619 15.9398 10.9619C15.94 10.9619 15.9405 10.9617 15.9424 10.961C16.0216 10.7167 16.1013 10.4715 16.1808 10.2263C16.1817 10.2263 16.182 10.2261 16.1825 10.2261C16.1827 10.2261 16.1831 10.2261 16.1834 10.2263C16.1839 10.2272 16.1843 10.2281 16.1847 10.2291C16.264 10.4729 16.3433 10.7165 16.4228 10.9603C16.4228 10.9605 16.4232 10.9608 16.4239 10.9619C16.6822 10.9619 16.9412 10.9619 17.1999 10.9619C17.2004 10.963 17.2004 10.9633 17.2004 10.9638C17.2004 10.964 17.2001 10.9644 17.1999 10.9647C16.9911 11.1167 16.7822 11.2685 16.5722 11.4214C16.5915 11.4827 16.6118 11.5439 16.6318 11.6052C16.6519 11.6668 16.6717 11.7283 16.6917 11.7899C16.7118 11.8514 16.7318 11.9129 16.7516 11.9745C16.7717 12.0358 16.7915 12.0973 16.8097 12.16C16.6009 12.0083 16.3922 11.8566 16.1827 11.7046C15.9736 11.8566 15.7647 12.0083 15.5558 12.16C15.5547 12.1594 15.554 12.1589 15.5533 12.1586Z"
                      fill="#EFEFEF"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_2655_12310"
                      x1="12"
                      y1="0.28125"
                      x2="12"
                      y2="15.418"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="white" />
                      <stop offset="1" stop-color="white" stop-opacity="0" />
                    </linearGradient>
                    <clipPath id="clip0_2655_12310">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 bg-white w-full p-[16px] rounded-[8px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
          <div className="mb-2">Your stats (disconnected)</div>
          <SelectionGroup
            className="px-[6px] w-max flex py-[4px] gap-[8px] items-center rounded-[8px] bg-[#f6f6f6] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]"
            defaultItem={mode}
          >
            <SelectionItem
              SelectedItem={
                <div className="cursor-pointer bg-white rounded-[8px] w-[100px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                  <div className="text-black font-semibold">Deposit</div>
                  <LightIcon />
                </div>
              }
              UnselectedItem={
                <div
                  onClick={() => setMode(0)}
                  className="cursor-pointer hover:bg-white rounded-[8px] w-[100px] flex justify-center"
                >
                  <div className="text-[#959595]">Deposit</div>
                </div>
              }
            />
            <SelectionItem
              SelectedItem={
                <div className="cursor-pointer bg-white rounded-[8px] w-[100px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                  <div className="text-black font-semibold">Withdraw</div>
                  <LightIcon />
                </div>
              }
              UnselectedItem={
                <div
                  onClick={() => setMode(1)}
                  className="cursor-pointer hover:bg-white rounded-[8px] w-[100px] flex justify-center"
                >
                  <div className="text-[#959595]">Withdraw</div>
                </div>
              }
            />
            <SelectionItem
              SelectedItem={
                <div className="cursor-pointer bg-white rounded-[8px] w-[100px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                  <div className="text-black font-semibold">Unlock</div>
                  <LightIcon />
                </div>
              }
              UnselectedItem={
                <div className="cursor-pointer hover:bg-white rounded-[8px] w-[100px] flex justify-center">
                  <div className="text-[#959595]">Unlock</div>
                </div>
              }
            />
          </SelectionGroup>

          <table className="w-full mt-6">
            <tr className="border-b-[1px] h-[40px]">
              <td>
                <div className="pl-2">Available</div>
              </td>
              <td>
                <div>Locked</div>
              </td>
              <td>
                <div>Total Value</div>
              </td>
              <td>
                <div>Est.Earnings</div>
              </td>
            </tr>
            <tr className="border-b-[1px] h-[60px]">
              <td>
                <div className="flex items-center gap-1 pl-2">
                  <span>0</span>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_2655_12328)">
                      <path
                        d="M15.8125 8C15.8125 12.3148 12.3148 15.8125 8 15.8125C3.68528 15.8125 0.1875 12.3148 0.1875 8C0.1875 3.68528 3.68528 0.1875 8 0.1875C12.3148 0.1875 15.8125 3.68528 15.8125 8Z"
                        fill="#5B1DEE"
                      />
                      <path
                        d="M15.8125 8C15.8125 12.3148 12.3148 15.8125 8 15.8125C3.68528 15.8125 0.1875 12.3148 0.1875 8C0.1875 3.68528 3.68528 0.1875 8 0.1875C12.3148 0.1875 15.8125 3.68528 15.8125 8Z"
                        fill="url(#paint0_linear_2655_12328)"
                        fill-opacity="0.18"
                      />
                      <path
                        opacity="0.1"
                        d="M4.54688 4.14266L8.24319 1.0625L11.9395 4.14266H4.54688Z"
                        fill="white"
                      />
                      <path
                        opacity="0.1"
                        d="M11.9395 4.1427L8.24319 7.22301L4.54688 4.1427H11.9395Z"
                        fill="white"
                      />
                      <path
                        opacity="0.1"
                        d="M11.9385 10.3031L8.24219 7.22301L11.9385 4.1427V10.3031Z"
                        fill="white"
                      />
                      <path
                        d="M6.27126 4.57649C6.32438 4.4127 6.37751 4.24907 6.43082 4.08497C6.29082 3.98318 6.15111 3.8817 6.01172 3.78039C6.01203 3.77961 6.01203 3.77945 6.01219 3.7793C6.01234 3.77914 6.0125 3.77899 6.01266 3.77899C6.01312 3.77884 6.01359 3.77884 6.01405 3.77884C6.18561 3.77884 6.35719 3.77868 6.52888 3.77868C6.52907 3.77868 6.52938 3.77852 6.53057 3.77805C6.58344 3.6152 6.63657 3.45172 6.68957 3.28824C6.69019 3.28824 6.69032 3.28809 6.69063 3.28809C6.69082 3.28809 6.69113 3.28809 6.69126 3.28824C6.69157 3.28886 6.69188 3.28949 6.69219 3.2901C6.74507 3.45265 6.79788 3.61504 6.85088 3.77759C6.85088 3.77775 6.85119 3.7779 6.85163 3.77868C7.02382 3.77868 7.19651 3.77868 7.36894 3.77868C7.36926 3.77945 7.36926 3.77961 7.36926 3.77992C7.36926 3.78007 7.36913 3.78039 7.36894 3.78054C7.22976 3.88186 7.09051 3.98303 6.95051 4.08497C6.96338 4.12584 6.97688 4.1667 6.99026 4.20758C7.00363 4.2486 7.01682 4.28963 7.03019 4.33065C7.04357 4.37168 7.05694 4.4127 7.07013 4.45373C7.08351 4.4946 7.09669 4.53562 7.10882 4.57742C6.96957 4.47626 6.83051 4.3751 6.69082 4.27378C6.55144 4.3751 6.41219 4.47626 6.27294 4.57742C6.27219 4.57712 6.27169 4.5768 6.27126 4.57649Z"
                        fill="#EFEFEF"
                      />
                      <path
                        d="M10.4095 10.8785L8.82007 12.1515L6.64014 13.8977L5.18359 12.684L5.83906 12.1515L8.9267 9.64258L10.4095 10.8785Z"
                        fill="white"
                      />
                      <path
                        d="M11.9377 12.151L8.24156 15.2313L6.64062 13.8972L8.82056 12.151L10.4099 10.8779L11.9377 12.151Z"
                        fill="#F5F5F5"
                      />
                      <path
                        d="M8.92879 9.64266L5.84119 12.1516L5.18572 12.6841L4.54688 12.1516L8.24317 9.07129L8.92879 9.64266Z"
                        fill="#F5F5F5"
                      />
                      <path
                        d="M6.00281 7.2043L4.54688 8.33624V5.99097L6.00281 7.2043Z"
                        fill="#F5F5F5"
                      />
                      <path
                        d="M7.44942 8.40935L4.54688 10.7935V8.33604L6.00281 7.2041L7.44942 8.40935Z"
                        fill="white"
                      />
                      <path
                        d="M8.24317 9.07074L4.54688 12.1511V10.7933L7.44942 8.40918L8.24317 9.07074Z"
                        fill="#F5F5F5"
                      />
                      <path
                        d="M7.64622 3.39095C7.69941 3.22716 7.75253 3.06353 7.80584 2.89943C7.66584 2.79764 7.52609 2.69616 7.38672 2.59485C7.38703 2.59406 7.38703 2.59391 7.38716 2.59376C7.38734 2.5936 7.38747 2.59345 7.38766 2.59345C7.38809 2.59329 7.38859 2.59329 7.38903 2.59329C7.56059 2.59329 7.73216 2.59313 7.90391 2.59313C7.90403 2.59313 7.90434 2.59298 7.90559 2.59251C7.95841 2.42966 8.01159 2.26618 8.06459 2.1027C8.06522 2.1027 8.06534 2.10254 8.06566 2.10254C8.06584 2.10254 8.06609 2.10254 8.06628 2.1027C8.06659 2.10332 8.06691 2.10394 8.06722 2.10456C8.12003 2.26711 8.17291 2.4295 8.22584 2.59205C8.22584 2.5922 8.22616 2.59236 8.22666 2.59313C8.39884 2.59313 8.57147 2.59313 8.74397 2.59313C8.74428 2.59391 8.74428 2.59406 8.74428 2.59438C8.74428 2.59453 8.74416 2.59485 8.74397 2.595C8.60472 2.69632 8.46547 2.79748 8.32547 2.89943C8.33841 2.9403 8.35191 2.98116 8.36528 3.02203C8.37866 3.06306 8.39184 3.10408 8.40522 3.14511C8.41859 3.18613 8.43191 3.22716 8.44516 3.26818C8.45853 3.30906 8.47172 3.35008 8.48384 3.39188C8.34459 3.29072 8.20553 3.18955 8.06584 3.08823C7.92641 3.18955 7.78716 3.29072 7.64797 3.39188C7.64716 3.39141 7.64672 3.39126 7.64622 3.39095Z"
                        fill="#EFEFEF"
                      />
                      <path
                        d="M7.64622 5.78157C7.69941 5.61778 7.75253 5.45415 7.80584 5.29005C7.66584 5.18826 7.52609 5.08678 7.38672 4.98546C7.38703 4.98469 7.38703 4.98453 7.38716 4.98438C7.38734 4.98422 7.38747 4.98406 7.38766 4.98406C7.38809 4.98391 7.38859 4.98391 7.38903 4.98391C7.56059 4.98391 7.73216 4.98376 7.90391 4.98376C7.90403 4.98376 7.90434 4.9836 7.90559 4.98313C7.95841 4.82028 8.01159 4.6568 8.06459 4.49331C8.06522 4.49331 8.06534 4.49316 8.06566 4.49316C8.06584 4.49316 8.06609 4.49316 8.06628 4.49331C8.06659 4.49394 8.06691 4.49456 8.06722 4.49518C8.12003 4.65773 8.17291 4.82012 8.22584 4.98266C8.22584 4.98282 8.22616 4.98298 8.22666 4.98376C8.39884 4.98376 8.57147 4.98376 8.74397 4.98376C8.74428 4.98453 8.74428 4.98469 8.74428 4.985C8.74428 4.98515 8.74416 4.98546 8.74397 4.98562C8.60472 5.08694 8.46547 5.1881 8.32547 5.29005C8.33841 5.33091 8.35191 5.37178 8.36528 5.41266C8.37866 5.45368 8.39184 5.49471 8.40522 5.53573C8.41859 5.57676 8.43191 5.61778 8.44516 5.65881C8.45853 5.69968 8.47172 5.7407 8.48384 5.7825C8.34459 5.68134 8.20553 5.58018 8.06584 5.47885C7.92641 5.58018 7.78716 5.68134 7.64797 5.7825C7.64716 5.78204 7.64672 5.78173 7.64622 5.78157Z"
                        fill="#EFEFEF"
                      />
                      <path
                        d="M9.0095 4.57637C9.06269 4.41258 9.11581 4.24895 9.16912 4.08485C9.02912 3.98306 8.88938 3.88158 8.75 3.78026C8.75031 3.77949 8.75031 3.77933 8.7505 3.77918C8.75062 3.77902 8.75075 3.77886 8.75094 3.77886C8.75137 3.77871 8.75188 3.77871 8.75231 3.77871C8.92388 3.77871 9.09544 3.77856 9.26719 3.77856C9.26731 3.77856 9.26763 3.7784 9.26888 3.77793C9.32169 3.61508 9.37487 3.4516 9.42787 3.28812C9.4285 3.28812 9.42862 3.28796 9.42894 3.28796C9.42912 3.28796 9.42944 3.28796 9.42956 3.28812C9.42988 3.28874 9.43019 3.28936 9.4305 3.28998C9.48331 3.45253 9.53619 3.61492 9.58912 3.77747C9.58912 3.77763 9.58944 3.77778 9.58994 3.77856C9.76212 3.77856 9.93475 3.77856 10.1073 3.77856C10.1076 3.77933 10.1076 3.77949 10.1076 3.7798C10.1076 3.77995 10.1074 3.78026 10.1073 3.78042C9.968 3.88174 9.82875 3.98291 9.68875 4.08485C9.70169 4.12571 9.71519 4.16658 9.72856 4.20746C9.74194 4.24848 9.75513 4.28951 9.7685 4.33053C9.78188 4.37156 9.79519 4.41258 9.80844 4.45361C9.82181 4.49448 9.835 4.5355 9.84713 4.5773C9.70788 4.47614 9.56881 4.37498 9.42912 4.27366C9.28969 4.37498 9.15044 4.47614 9.01125 4.5773C9.01044 4.577 9.01 4.57668 9.0095 4.57637Z"
                        fill="#EFEFEF"
                      />
                      <path
                        d="M9.0095 6.91732C9.06269 6.75351 9.11581 6.58988 9.16912 6.42582C9.02912 6.32401 8.88938 6.22253 8.75 6.12121C8.75031 6.12044 8.75031 6.12028 8.7505 6.12013C8.75062 6.11997 8.75075 6.11981 8.75094 6.11981C8.75137 6.11966 8.75188 6.11966 8.75231 6.11966C8.92388 6.11966 9.09544 6.1195 9.26719 6.1195C9.26731 6.1195 9.26763 6.11934 9.26888 6.11888C9.32169 5.95602 9.37487 5.79254 9.42787 5.62906C9.4285 5.62906 9.42862 5.62891 9.42894 5.62891C9.42912 5.62891 9.42944 5.62891 9.42956 5.62906C9.42988 5.62969 9.43019 5.63031 9.4305 5.63093C9.48331 5.79348 9.53619 5.95587 9.58912 6.11841C9.58912 6.11857 9.58944 6.11873 9.58994 6.1195C9.76212 6.1195 9.93475 6.1195 10.1073 6.1195C10.1076 6.12028 10.1076 6.12044 10.1076 6.12074C10.1076 6.1209 10.1074 6.12121 10.1073 6.12137C9.968 6.22269 9.82875 6.32388 9.68875 6.42582C9.70169 6.46663 9.71519 6.50751 9.72856 6.54838C9.74194 6.58944 9.75513 6.63044 9.7685 6.67151C9.78188 6.71251 9.79519 6.75351 9.80844 6.79457C9.82181 6.83544 9.835 6.87644 9.84713 6.91826C9.70788 6.81707 9.56881 6.71594 9.42912 6.61463C9.28969 6.71594 9.15044 6.81707 9.01125 6.91826C9.01044 6.91776 9.01 6.91763 9.0095 6.91732Z"
                        fill="#EFEFEF"
                      />
                      <path
                        d="M10.4236 5.74349C10.4767 5.5797 10.5299 5.41607 10.5832 5.25197C10.4432 5.15018 10.3035 5.0487 10.1641 4.94738C10.1644 4.94661 10.1644 4.94645 10.1646 4.9463C10.1647 4.94614 10.1649 4.94598 10.165 4.94598C10.1655 4.94583 10.1659 4.94583 10.1664 4.94583C10.338 4.94583 10.5096 4.94567 10.6812 4.94567C10.6814 4.94567 10.6817 4.94552 10.6829 4.94505C10.7358 4.7822 10.7889 4.61872 10.8419 4.45523C10.8426 4.45523 10.8427 4.45508 10.843 4.45508C10.8432 4.45508 10.8435 4.45508 10.8436 4.45523C10.8439 4.45586 10.8442 4.45648 10.8446 4.4571C10.8974 4.61965 10.9502 4.78204 11.0032 4.94458C11.0032 4.94474 11.0036 4.9449 11.004 4.94567C11.1762 4.94567 11.3489 4.94567 11.5213 4.94567C11.5216 4.94645 11.5216 4.94661 11.5216 4.94692C11.5216 4.94707 11.5215 4.94738 11.5213 4.94754C11.3821 5.04886 11.2429 5.15002 11.1029 5.25197C11.1157 5.29283 11.1292 5.3337 11.1426 5.37457C11.156 5.4156 11.1692 5.45662 11.1826 5.49765C11.1959 5.53867 11.2093 5.5797 11.2225 5.62073C11.2359 5.6616 11.2491 5.70262 11.2612 5.74442C11.1219 5.64326 10.9829 5.54209 10.8432 5.44077C10.7038 5.54209 10.5646 5.64326 10.4253 5.74442C10.4244 5.74411 10.4241 5.7438 10.4236 5.74349Z"
                        fill="#EFEFEF"
                      />
                      <path
                        d="M10.3689 8.10576C10.422 7.94195 10.4752 7.77832 10.5284 7.61426C10.3884 7.51245 10.2488 7.41095 10.1094 7.30963C10.1097 7.30888 10.1097 7.3087 10.1098 7.30857C10.11 7.30838 10.1101 7.30826 10.1103 7.30826C10.1107 7.30807 10.1112 7.30807 10.1117 7.30807C10.2833 7.30807 10.4548 7.30795 10.6265 7.30795C10.6267 7.30795 10.627 7.30782 10.6283 7.30732C10.6811 7.14445 10.7342 6.98101 10.7872 6.81751C10.7878 6.81751 10.788 6.81738 10.7883 6.81738C10.7884 6.81738 10.7888 6.81738 10.7889 6.81751C10.7893 6.81813 10.7895 6.81876 10.7898 6.81938C10.8427 6.98195 10.8955 7.14432 10.9485 7.30688C10.9485 7.30701 10.9488 7.3072 10.9493 7.30795C11.1214 7.30795 11.2941 7.30795 11.4666 7.30795C11.4669 7.3087 11.4669 7.30888 11.4669 7.3092C11.4669 7.30932 11.4668 7.30963 11.4666 7.30982C11.3274 7.41113 11.1881 7.51232 11.0481 7.61426C11.061 7.65513 11.0745 7.69595 11.0879 7.73682C11.1013 7.77788 11.1144 7.81888 11.1278 7.85995C11.1412 7.90095 11.1546 7.94195 11.1678 7.98301C11.1811 8.02388 11.1943 8.06488 11.2064 8.1067C11.0673 8.00551 10.9281 7.90438 10.7884 7.80307C10.6491 7.90438 10.5098 8.00551 10.3706 8.1067C10.3698 8.10626 10.3693 8.10595 10.3689 8.10576Z"
                        fill="#EFEFEF"
                      />
                    </g>
                    <defs>
                      <linearGradient
                        id="paint0_linear_2655_12328"
                        x1="8"
                        y1="0.1875"
                        x2="8"
                        y2="10.2786"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="white" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </linearGradient>
                      <clipPath id="clip0_2655_12328">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </td>
              <td>
                <div className="flex items-center gap-1">
                  <span>0</span>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_2655_12328)">
                      <path
                        d="M15.8125 8C15.8125 12.3148 12.3148 15.8125 8 15.8125C3.68528 15.8125 0.1875 12.3148 0.1875 8C0.1875 3.68528 3.68528 0.1875 8 0.1875C12.3148 0.1875 15.8125 3.68528 15.8125 8Z"
                        fill="#5B1DEE"
                      />
                      <path
                        d="M15.8125 8C15.8125 12.3148 12.3148 15.8125 8 15.8125C3.68528 15.8125 0.1875 12.3148 0.1875 8C0.1875 3.68528 3.68528 0.1875 8 0.1875C12.3148 0.1875 15.8125 3.68528 15.8125 8Z"
                        fill="url(#paint0_linear_2655_12328)"
                        fill-opacity="0.18"
                      />
                      <path
                        opacity="0.1"
                        d="M4.54688 4.14266L8.24319 1.0625L11.9395 4.14266H4.54688Z"
                        fill="white"
                      />
                      <path
                        opacity="0.1"
                        d="M11.9395 4.1427L8.24319 7.22301L4.54688 4.1427H11.9395Z"
                        fill="white"
                      />
                      <path
                        opacity="0.1"
                        d="M11.9385 10.3031L8.24219 7.22301L11.9385 4.1427V10.3031Z"
                        fill="white"
                      />
                      <path
                        d="M6.27126 4.57649C6.32438 4.4127 6.37751 4.24907 6.43082 4.08497C6.29082 3.98318 6.15111 3.8817 6.01172 3.78039C6.01203 3.77961 6.01203 3.77945 6.01219 3.7793C6.01234 3.77914 6.0125 3.77899 6.01266 3.77899C6.01312 3.77884 6.01359 3.77884 6.01405 3.77884C6.18561 3.77884 6.35719 3.77868 6.52888 3.77868C6.52907 3.77868 6.52938 3.77852 6.53057 3.77805C6.58344 3.6152 6.63657 3.45172 6.68957 3.28824C6.69019 3.28824 6.69032 3.28809 6.69063 3.28809C6.69082 3.28809 6.69113 3.28809 6.69126 3.28824C6.69157 3.28886 6.69188 3.28949 6.69219 3.2901C6.74507 3.45265 6.79788 3.61504 6.85088 3.77759C6.85088 3.77775 6.85119 3.7779 6.85163 3.77868C7.02382 3.77868 7.19651 3.77868 7.36894 3.77868C7.36926 3.77945 7.36926 3.77961 7.36926 3.77992C7.36926 3.78007 7.36913 3.78039 7.36894 3.78054C7.22976 3.88186 7.09051 3.98303 6.95051 4.08497C6.96338 4.12584 6.97688 4.1667 6.99026 4.20758C7.00363 4.2486 7.01682 4.28963 7.03019 4.33065C7.04357 4.37168 7.05694 4.4127 7.07013 4.45373C7.08351 4.4946 7.09669 4.53562 7.10882 4.57742C6.96957 4.47626 6.83051 4.3751 6.69082 4.27378C6.55144 4.3751 6.41219 4.47626 6.27294 4.57742C6.27219 4.57712 6.27169 4.5768 6.27126 4.57649Z"
                        fill="#EFEFEF"
                      />
                      <path
                        d="M10.4095 10.8785L8.82007 12.1515L6.64014 13.8977L5.18359 12.684L5.83906 12.1515L8.9267 9.64258L10.4095 10.8785Z"
                        fill="white"
                      />
                      <path
                        d="M11.9377 12.151L8.24156 15.2313L6.64062 13.8972L8.82056 12.151L10.4099 10.8779L11.9377 12.151Z"
                        fill="#F5F5F5"
                      />
                      <path
                        d="M8.92879 9.64266L5.84119 12.1516L5.18572 12.6841L4.54688 12.1516L8.24317 9.07129L8.92879 9.64266Z"
                        fill="#F5F5F5"
                      />
                      <path
                        d="M6.00281 7.2043L4.54688 8.33624V5.99097L6.00281 7.2043Z"
                        fill="#F5F5F5"
                      />
                      <path
                        d="M7.44942 8.40935L4.54688 10.7935V8.33604L6.00281 7.2041L7.44942 8.40935Z"
                        fill="white"
                      />
                      <path
                        d="M8.24317 9.07074L4.54688 12.1511V10.7933L7.44942 8.40918L8.24317 9.07074Z"
                        fill="#F5F5F5"
                      />
                      <path
                        d="M7.64622 3.39095C7.69941 3.22716 7.75253 3.06353 7.80584 2.89943C7.66584 2.79764 7.52609 2.69616 7.38672 2.59485C7.38703 2.59406 7.38703 2.59391 7.38716 2.59376C7.38734 2.5936 7.38747 2.59345 7.38766 2.59345C7.38809 2.59329 7.38859 2.59329 7.38903 2.59329C7.56059 2.59329 7.73216 2.59313 7.90391 2.59313C7.90403 2.59313 7.90434 2.59298 7.90559 2.59251C7.95841 2.42966 8.01159 2.26618 8.06459 2.1027C8.06522 2.1027 8.06534 2.10254 8.06566 2.10254C8.06584 2.10254 8.06609 2.10254 8.06628 2.1027C8.06659 2.10332 8.06691 2.10394 8.06722 2.10456C8.12003 2.26711 8.17291 2.4295 8.22584 2.59205C8.22584 2.5922 8.22616 2.59236 8.22666 2.59313C8.39884 2.59313 8.57147 2.59313 8.74397 2.59313C8.74428 2.59391 8.74428 2.59406 8.74428 2.59438C8.74428 2.59453 8.74416 2.59485 8.74397 2.595C8.60472 2.69632 8.46547 2.79748 8.32547 2.89943C8.33841 2.9403 8.35191 2.98116 8.36528 3.02203C8.37866 3.06306 8.39184 3.10408 8.40522 3.14511C8.41859 3.18613 8.43191 3.22716 8.44516 3.26818C8.45853 3.30906 8.47172 3.35008 8.48384 3.39188C8.34459 3.29072 8.20553 3.18955 8.06584 3.08823C7.92641 3.18955 7.78716 3.29072 7.64797 3.39188C7.64716 3.39141 7.64672 3.39126 7.64622 3.39095Z"
                        fill="#EFEFEF"
                      />
                      <path
                        d="M7.64622 5.78157C7.69941 5.61778 7.75253 5.45415 7.80584 5.29005C7.66584 5.18826 7.52609 5.08678 7.38672 4.98546C7.38703 4.98469 7.38703 4.98453 7.38716 4.98438C7.38734 4.98422 7.38747 4.98406 7.38766 4.98406C7.38809 4.98391 7.38859 4.98391 7.38903 4.98391C7.56059 4.98391 7.73216 4.98376 7.90391 4.98376C7.90403 4.98376 7.90434 4.9836 7.90559 4.98313C7.95841 4.82028 8.01159 4.6568 8.06459 4.49331C8.06522 4.49331 8.06534 4.49316 8.06566 4.49316C8.06584 4.49316 8.06609 4.49316 8.06628 4.49331C8.06659 4.49394 8.06691 4.49456 8.06722 4.49518C8.12003 4.65773 8.17291 4.82012 8.22584 4.98266C8.22584 4.98282 8.22616 4.98298 8.22666 4.98376C8.39884 4.98376 8.57147 4.98376 8.74397 4.98376C8.74428 4.98453 8.74428 4.98469 8.74428 4.985C8.74428 4.98515 8.74416 4.98546 8.74397 4.98562C8.60472 5.08694 8.46547 5.1881 8.32547 5.29005C8.33841 5.33091 8.35191 5.37178 8.36528 5.41266C8.37866 5.45368 8.39184 5.49471 8.40522 5.53573C8.41859 5.57676 8.43191 5.61778 8.44516 5.65881C8.45853 5.69968 8.47172 5.7407 8.48384 5.7825C8.34459 5.68134 8.20553 5.58018 8.06584 5.47885C7.92641 5.58018 7.78716 5.68134 7.64797 5.7825C7.64716 5.78204 7.64672 5.78173 7.64622 5.78157Z"
                        fill="#EFEFEF"
                      />
                      <path
                        d="M9.0095 4.57637C9.06269 4.41258 9.11581 4.24895 9.16912 4.08485C9.02912 3.98306 8.88938 3.88158 8.75 3.78026C8.75031 3.77949 8.75031 3.77933 8.7505 3.77918C8.75062 3.77902 8.75075 3.77886 8.75094 3.77886C8.75137 3.77871 8.75188 3.77871 8.75231 3.77871C8.92388 3.77871 9.09544 3.77856 9.26719 3.77856C9.26731 3.77856 9.26763 3.7784 9.26888 3.77793C9.32169 3.61508 9.37487 3.4516 9.42787 3.28812C9.4285 3.28812 9.42862 3.28796 9.42894 3.28796C9.42912 3.28796 9.42944 3.28796 9.42956 3.28812C9.42988 3.28874 9.43019 3.28936 9.4305 3.28998C9.48331 3.45253 9.53619 3.61492 9.58912 3.77747C9.58912 3.77763 9.58944 3.77778 9.58994 3.77856C9.76212 3.77856 9.93475 3.77856 10.1073 3.77856C10.1076 3.77933 10.1076 3.77949 10.1076 3.7798C10.1076 3.77995 10.1074 3.78026 10.1073 3.78042C9.968 3.88174 9.82875 3.98291 9.68875 4.08485C9.70169 4.12571 9.71519 4.16658 9.72856 4.20746C9.74194 4.24848 9.75513 4.28951 9.7685 4.33053C9.78188 4.37156 9.79519 4.41258 9.80844 4.45361C9.82181 4.49448 9.835 4.5355 9.84713 4.5773C9.70788 4.47614 9.56881 4.37498 9.42912 4.27366C9.28969 4.37498 9.15044 4.47614 9.01125 4.5773C9.01044 4.577 9.01 4.57668 9.0095 4.57637Z"
                        fill="#EFEFEF"
                      />
                      <path
                        d="M9.0095 6.91732C9.06269 6.75351 9.11581 6.58988 9.16912 6.42582C9.02912 6.32401 8.88938 6.22253 8.75 6.12121C8.75031 6.12044 8.75031 6.12028 8.7505 6.12013C8.75062 6.11997 8.75075 6.11981 8.75094 6.11981C8.75137 6.11966 8.75188 6.11966 8.75231 6.11966C8.92388 6.11966 9.09544 6.1195 9.26719 6.1195C9.26731 6.1195 9.26763 6.11934 9.26888 6.11888C9.32169 5.95602 9.37487 5.79254 9.42787 5.62906C9.4285 5.62906 9.42862 5.62891 9.42894 5.62891C9.42912 5.62891 9.42944 5.62891 9.42956 5.62906C9.42988 5.62969 9.43019 5.63031 9.4305 5.63093C9.48331 5.79348 9.53619 5.95587 9.58912 6.11841C9.58912 6.11857 9.58944 6.11873 9.58994 6.1195C9.76212 6.1195 9.93475 6.1195 10.1073 6.1195C10.1076 6.12028 10.1076 6.12044 10.1076 6.12074C10.1076 6.1209 10.1074 6.12121 10.1073 6.12137C9.968 6.22269 9.82875 6.32388 9.68875 6.42582C9.70169 6.46663 9.71519 6.50751 9.72856 6.54838C9.74194 6.58944 9.75513 6.63044 9.7685 6.67151C9.78188 6.71251 9.79519 6.75351 9.80844 6.79457C9.82181 6.83544 9.835 6.87644 9.84713 6.91826C9.70788 6.81707 9.56881 6.71594 9.42912 6.61463C9.28969 6.71594 9.15044 6.81707 9.01125 6.91826C9.01044 6.91776 9.01 6.91763 9.0095 6.91732Z"
                        fill="#EFEFEF"
                      />
                      <path
                        d="M10.4236 5.74349C10.4767 5.5797 10.5299 5.41607 10.5832 5.25197C10.4432 5.15018 10.3035 5.0487 10.1641 4.94738C10.1644 4.94661 10.1644 4.94645 10.1646 4.9463C10.1647 4.94614 10.1649 4.94598 10.165 4.94598C10.1655 4.94583 10.1659 4.94583 10.1664 4.94583C10.338 4.94583 10.5096 4.94567 10.6812 4.94567C10.6814 4.94567 10.6817 4.94552 10.6829 4.94505C10.7358 4.7822 10.7889 4.61872 10.8419 4.45523C10.8426 4.45523 10.8427 4.45508 10.843 4.45508C10.8432 4.45508 10.8435 4.45508 10.8436 4.45523C10.8439 4.45586 10.8442 4.45648 10.8446 4.4571C10.8974 4.61965 10.9502 4.78204 11.0032 4.94458C11.0032 4.94474 11.0036 4.9449 11.004 4.94567C11.1762 4.94567 11.3489 4.94567 11.5213 4.94567C11.5216 4.94645 11.5216 4.94661 11.5216 4.94692C11.5216 4.94707 11.5215 4.94738 11.5213 4.94754C11.3821 5.04886 11.2429 5.15002 11.1029 5.25197C11.1157 5.29283 11.1292 5.3337 11.1426 5.37457C11.156 5.4156 11.1692 5.45662 11.1826 5.49765C11.1959 5.53867 11.2093 5.5797 11.2225 5.62073C11.2359 5.6616 11.2491 5.70262 11.2612 5.74442C11.1219 5.64326 10.9829 5.54209 10.8432 5.44077C10.7038 5.54209 10.5646 5.64326 10.4253 5.74442C10.4244 5.74411 10.4241 5.7438 10.4236 5.74349Z"
                        fill="#EFEFEF"
                      />
                      <path
                        d="M10.3689 8.10576C10.422 7.94195 10.4752 7.77832 10.5284 7.61426C10.3884 7.51245 10.2488 7.41095 10.1094 7.30963C10.1097 7.30888 10.1097 7.3087 10.1098 7.30857C10.11 7.30838 10.1101 7.30826 10.1103 7.30826C10.1107 7.30807 10.1112 7.30807 10.1117 7.30807C10.2833 7.30807 10.4548 7.30795 10.6265 7.30795C10.6267 7.30795 10.627 7.30782 10.6283 7.30732C10.6811 7.14445 10.7342 6.98101 10.7872 6.81751C10.7878 6.81751 10.788 6.81738 10.7883 6.81738C10.7884 6.81738 10.7888 6.81738 10.7889 6.81751C10.7893 6.81813 10.7895 6.81876 10.7898 6.81938C10.8427 6.98195 10.8955 7.14432 10.9485 7.30688C10.9485 7.30701 10.9488 7.3072 10.9493 7.30795C11.1214 7.30795 11.2941 7.30795 11.4666 7.30795C11.4669 7.3087 11.4669 7.30888 11.4669 7.3092C11.4669 7.30932 11.4668 7.30963 11.4666 7.30982C11.3274 7.41113 11.1881 7.51232 11.0481 7.61426C11.061 7.65513 11.0745 7.69595 11.0879 7.73682C11.1013 7.77788 11.1144 7.81888 11.1278 7.85995C11.1412 7.90095 11.1546 7.94195 11.1678 7.98301C11.1811 8.02388 11.1943 8.06488 11.2064 8.1067C11.0673 8.00551 10.9281 7.90438 10.7884 7.80307C10.6491 7.90438 10.5098 8.00551 10.3706 8.1067C10.3698 8.10626 10.3693 8.10595 10.3689 8.10576Z"
                        fill="#EFEFEF"
                      />
                    </g>
                    <defs>
                      <linearGradient
                        id="paint0_linear_2655_12328"
                        x1="8"
                        y1="0.1875"
                        x2="8"
                        y2="10.2786"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="white" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </linearGradient>
                      <clipPath id="clip0_2655_12328">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </td>
              <td>
                <div className="flex items-center gap-1">
                  <span>0</span>
                  <NUSDIcon />
                </div>
              </td>
              <td>
                <div className="flex items-center gap-1">
                  <span>0</span>
                  <NUSDIcon />
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div className="mt-4 bg-white w-full p-[16px] rounded-[8px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
          <div className="w-full grid grid-cols-2 gap-4">
            {mode === 0 && (
              <div className="space-y-[10px]">
                <div className="text-[20px]">Description</div>
                <div className="text-[#202020] font-normal">
                  Deposit USDC in exchange for gNUSD, an ERC-20 representing
                  your ownership in the vault. Stakers receie fees from each
                  trade placed on the platform in exchange for serving as the
                  counterparty to all trades. gNUSD accumulates these fees in
                  real-time.
                </div>
                <div className="w-full rounded-md border-[1px] bg-[#FAFAFA] border-[#E3E3E3] p-[16px]">
                  Please be aware, you can’t immediately withdraw your assets.
                  Withdraws follow an epoch system described on the Withdraw
                  panel.
                </div>
              </div>
            )}
            {mode === 1 && (
              <div className="space-y-[10px]">
                <div className="text-[20px]">Withdrawal Requests</div>
                <div className="text-[#202020] font-normal">
                  <p>
                    Withdraws follow an epoch system. Each epoch is{" "}
                    <span className="font-semibold">72</span> hours long. You
                    can make a request to withdraw your assets during the first
                    <span className="font-semibold">48</span> hours of any
                    epoch, but you must wait until a specific withdraw epoch to
                    actually withdraw them.
                  </p>
                  <p>
                    Depending on the collateralization ratio of the vault, your
                    withdraw epoch will be between{" "}
                    <span className="font-semibold">1</span> and{" "}
                    <span className="font-semibold">3</span> epochs later.
                  </p>
                  <p>
                    You must withdraw your assets in the first{" "}
                    <span className="font-semibold">48</span> hours of your
                    withdraw epoch, otherwise a new request is required.
                  </p>
                </div>
                <div className="w-full rounded-md border-[1px] bg-[#FAFAFA] border-[#E3E3E3] p-[16px]">
                  Remining time to withdraw in current epoch:{" "}
                  <span className="text-[#DB1F22]">Ended</span>
                </div>
              </div>
            )}

            <div>
              <SelectionGroup
                className="px-[6px] w-max flex py-[4px] gap-[8px] items-center rounded-[8px] bg-[#f6f6f6] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]"
                defaultItem={0}
              >
                <SelectionItem
                  SelectedItem={
                    <div className="cursor-pointer bg-white rounded-[8px] w-[100px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                      <div className="text-black font-semibold">Deposit</div>
                      <LightIcon />
                    </div>
                  }
                  UnselectedItem={
                    <div className="cursor-pointer hover:bg-white rounded-[8px] w-[100px] flex justify-center">
                      <div className="text-[#959595]">Deposit</div>
                    </div>
                  }
                />
                <SelectionItem
                  SelectedItem={
                    <div className="cursor-pointer bg-white rounded-[8px] w-[250px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                      <div className="text-black font-semibold">
                        Deposit with discount & lock
                      </div>
                      <LightIcon />
                    </div>
                  }
                  UnselectedItem={
                    <div className="cursor-pointer hover:bg-white rounded-[8px] w-[250px] flex justify-center">
                      <div className="text-[#959595]">
                        Deposit with discount & lock
                      </div>
                    </div>
                  }
                />
              </SelectionGroup>

              <div className="w-full flex flex-col items-center">
                <div className="w-full p-[12px] border-[2px] rounded-[8px] bg-[#F6F6F6] mt-[12px]">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <div className="text-[18px]">Deposit</div>
                      <div className="text-[#5A5A5A] font-normal text-[10px]">
                        (Up to 20,691.14)
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="font-normal text-[12px]">0</div>
                      <div className="py-[2px] px-[6px] bg-white border-[2px] rounded-[8px]">
                        <div className="text-[12px]">MAX</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-[20px]">500</div>
                    <div className="flex gap-1 items-center">
                      <NUSDIcon />

                      <div>USDC</div>
                    </div>
                  </div>
                </div>
                <div className="border-[2px] p-2 w-max rounded-full bg-white mt-[-16px] z-10">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.58284 16.233C9.40654 18.9994 10.3184 20.3826 11.9223 20.3923C13.5262 20.4019 14.4749 18.9998 16.3722 16.1955C16.9606 15.3258 17.511 14.4534 18.0404 13.5397C19.6054 10.8387 20.3879 9.48821 19.6445 8.03308C18.901 6.57796 17.4077 6.4267 14.4211 6.12416C12.826 5.96259 11.2887 5.95871 9.69645 6.11251C6.6629 6.40554 5.14612 6.55205 4.39356 7.99316C3.641 9.43427 4.43052 10.8392 6.00954 13.649C6.5129 14.5447 7.02978 15.3941 7.58284 16.233Z"
                      fill="#5B1DEE"
                    />
                    <path
                      d="M7.58284 16.233C9.40654 18.9994 10.3184 20.3826 11.9223 20.3923C13.5262 20.4019 14.4749 18.9998 16.3722 16.1955C16.9606 15.3258 17.511 14.4534 18.0404 13.5397C19.6054 10.8387 20.3879 9.48821 19.6445 8.03308C18.901 6.57796 17.4077 6.4267 14.4211 6.12416C12.826 5.96259 11.2887 5.95871 9.69645 6.11251C6.6629 6.40554 5.14612 6.55205 4.39356 7.99316C3.641 9.43427 4.43052 10.8392 6.00954 13.649C6.5129 14.5447 7.02978 15.3941 7.58284 16.233Z"
                      fill="url(#paint0_linear_2615_14727)"
                      fill-opacity="0.18"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2615_14727"
                        x1="12"
                        y1="22"
                        x2="12"
                        y2="11.6667"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="white" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="w-full p-[12px] border-[2px] rounded-[8px] bg-[#F6F6F6] mt-[-16px]">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <div className="text-[18px]">You receive</div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-[20px]">500</div>
                    <div className="flex gap-1 items-center">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_2655_12418)">
                          <path
                            d="M23.7188 12C23.7188 18.4721 18.4721 23.7188 12 23.7188C5.52791 23.7188 0.28125 18.4721 0.28125 12C0.28125 5.52791 5.52791 0.28125 12 0.28125C18.4721 0.28125 23.7188 5.52791 23.7188 12Z"
                            fill="#5B1DEE"
                          />
                          <path
                            d="M23.7188 12C23.7188 18.4721 18.4721 23.7188 12 23.7188C5.52791 23.7188 0.28125 18.4721 0.28125 12C0.28125 5.52791 5.52791 0.28125 12 0.28125C18.4721 0.28125 23.7188 5.52791 23.7188 12Z"
                            fill="url(#paint0_linear_2655_12418)"
                            fill-opacity="0.18"
                          />
                          <path
                            opacity="0.1"
                            d="M6.81641 6.21398L12.3609 1.59375L17.9053 6.21398H6.81641Z"
                            fill="white"
                          />
                          <path
                            opacity="0.1"
                            d="M17.9053 6.21411L12.3609 10.8346L6.81641 6.21411H17.9053Z"
                            fill="white"
                          />
                          <path
                            opacity="0.1"
                            d="M17.9038 15.4548L12.3594 10.8346L17.9038 6.21411V15.4548Z"
                            fill="white"
                          />
                          <path
                            d="M9.40493 6.86462C9.48462 6.61894 9.56431 6.37348 9.64428 6.12733C9.43428 5.97465 9.22472 5.82244 9.01562 5.67046C9.01609 5.66929 9.01609 5.66906 9.01633 5.66883C9.01656 5.66859 9.0168 5.66836 9.01703 5.66836C9.01772 5.66813 9.01843 5.66813 9.01912 5.66813C9.27647 5.66813 9.53384 5.6679 9.79137 5.6679C9.79165 5.6679 9.79212 5.66766 9.7939 5.66696C9.87321 5.42268 9.9529 5.17745 10.0324 4.93224C10.0333 4.93224 10.0335 4.93201 10.034 4.93201C10.0343 4.93201 10.0347 4.93201 10.0349 4.93224C10.0354 4.93317 10.0359 4.93411 10.0363 4.93503C10.1157 5.17886 10.1949 5.42244 10.2744 5.66627C10.2744 5.6665 10.2748 5.66673 10.2755 5.6679C10.5338 5.6679 10.7928 5.6679 11.0515 5.6679C11.0519 5.66906 11.0519 5.66929 11.0519 5.66976C11.0519 5.66999 11.0517 5.67046 11.0515 5.67069C10.8427 5.82267 10.6338 5.97442 10.4238 6.12733C10.4431 6.18863 10.4634 6.24994 10.4834 6.31125C10.5035 6.37279 10.5233 6.43432 10.5433 6.49586C10.5634 6.5574 10.5835 6.61894 10.6032 6.68047C10.6233 6.74178 10.6431 6.80331 10.6613 6.86601C10.4524 6.71427 10.2438 6.56253 10.0343 6.41055C9.82521 6.56253 9.61634 6.71427 9.40746 6.86601C9.40634 6.86555 9.40559 6.86509 9.40493 6.86462Z"
                            fill="#EFEFEF"
                          />
                          <path
                            d="M15.6123 16.3177L13.2282 18.2273L9.95825 20.8466L7.77344 19.026L8.75664 18.2273L13.3881 14.4639L15.6123 16.3177Z"
                            fill="white"
                          />
                          <path
                            d="M17.9065 18.2265L12.3623 22.847L9.96094 20.8458L13.2308 18.2265L15.6149 16.3169L17.9065 18.2265Z"
                            fill="#F5F5F5"
                          />
                          <path
                            d="M13.3893 14.4639L8.75788 18.2273L7.77467 19.026L6.81641 18.2273L12.3608 13.6068L13.3893 14.4639Z"
                            fill="#F5F5F5"
                          />
                          <path
                            d="M9.0003 10.8066L6.81641 12.5045V8.98657L9.0003 10.8066Z"
                            fill="#F5F5F5"
                          />
                          <path
                            d="M11.1702 12.614L6.81641 16.1902V12.5041L9.0003 10.8062L11.1702 12.614Z"
                            fill="white"
                          />
                          <path
                            d="M12.3608 13.6061L6.81641 18.2266V16.19L11.1702 12.6138L12.3608 13.6061Z"
                            fill="#F5F5F5"
                          />
                          <path
                            d="M11.4674 5.08643C11.5472 4.84074 11.6268 4.59529 11.7068 4.34914C11.4968 4.19646 11.2872 4.04425 11.0781 3.89227C11.0786 3.8911 11.0786 3.89087 11.0788 3.89064C11.0791 3.8904 11.0792 3.89017 11.0795 3.89017C11.0802 3.88993 11.0809 3.88993 11.0816 3.88993C11.3389 3.88993 11.5963 3.8897 11.8539 3.8897C11.8541 3.8897 11.8546 3.88946 11.8564 3.88877C11.9357 3.64449 12.0154 3.39926 12.0949 3.15404C12.0959 3.15404 12.0961 3.15381 12.0965 3.15381C12.0968 3.15381 12.0972 3.15381 12.0975 3.15404C12.0979 3.15498 12.0984 3.15591 12.0989 3.15685C12.1781 3.40066 12.2574 3.64425 12.3368 3.88807C12.3368 3.8883 12.3373 3.88854 12.338 3.8897C12.5963 3.8897 12.8552 3.8897 13.114 3.8897C13.1145 3.89087 13.1145 3.8911 13.1145 3.89156C13.1145 3.8918 13.1143 3.89227 13.114 3.8925C12.9051 4.04448 12.6962 4.19622 12.4862 4.34914C12.5057 4.41044 12.5259 4.47175 12.546 4.53305C12.566 4.59459 12.5858 4.65612 12.6059 4.71766C12.6259 4.7792 12.6459 4.84074 12.6658 4.90227C12.6858 4.96359 12.7056 5.02512 12.7238 5.08782C12.5149 4.93608 12.3063 4.78433 12.0968 4.63235C11.8877 4.78433 11.6788 4.93608 11.47 5.08782C11.4688 5.08712 11.4681 5.0869 11.4674 5.08643Z"
                            fill="#EFEFEF"
                          />
                          <path
                            d="M11.4674 8.67236C11.5472 8.42667 11.6268 8.18122 11.7068 7.93507C11.4968 7.78239 11.2872 7.63017 11.0781 7.4782C11.0786 7.47703 11.0786 7.4768 11.0788 7.47656C11.0791 7.47633 11.0792 7.4761 11.0795 7.4761C11.0802 7.47586 11.0809 7.47586 11.0816 7.47586C11.3389 7.47586 11.5963 7.47564 11.8539 7.47564C11.8541 7.47564 11.8546 7.4754 11.8564 7.4747C11.9357 7.23041 12.0154 6.98519 12.0949 6.73997C12.0959 6.73997 12.0961 6.73975 12.0965 6.73975C12.0968 6.73975 12.0972 6.73975 12.0975 6.73997C12.0979 6.74091 12.0984 6.74184 12.0989 6.74277C12.1781 6.98659 12.2574 7.23018 12.3368 7.474C12.3368 7.47423 12.3373 7.47447 12.338 7.47564C12.5963 7.47564 12.8552 7.47564 13.114 7.47564C13.1145 7.4768 13.1145 7.47703 13.1145 7.47749C13.1145 7.47773 13.1143 7.4782 13.114 7.47843C12.9051 7.63041 12.6962 7.78215 12.4862 7.93507C12.5057 7.99637 12.5259 8.05767 12.546 8.11899C12.566 8.18052 12.5858 8.24206 12.6059 8.3036C12.6259 8.36514 12.6459 8.42667 12.6658 8.48821C12.6858 8.54952 12.7056 8.61105 12.7238 8.67375C12.5149 8.52201 12.3063 8.37027 12.0968 8.21828C11.8877 8.37027 11.6788 8.52201 11.47 8.67375C11.4688 8.67306 11.4681 8.67259 11.4674 8.67236Z"
                            fill="#EFEFEF"
                          />
                          <path
                            d="M13.5182 6.86462C13.5979 6.61894 13.6776 6.37348 13.7576 6.12733C13.5476 5.97465 13.338 5.82244 13.1289 5.67046C13.1294 5.66929 13.1294 5.66906 13.1297 5.66883C13.1298 5.66859 13.13 5.66836 13.1303 5.66836C13.131 5.66813 13.1317 5.66813 13.1324 5.66813C13.3897 5.66813 13.6471 5.6679 13.9047 5.6679C13.9049 5.6679 13.9053 5.66766 13.9072 5.66696C13.9864 5.42268 14.0662 5.17745 14.1457 4.93224C14.1467 4.93224 14.1468 4.93201 14.1473 4.93201C14.1476 4.93201 14.1481 4.93201 14.1483 4.93224C14.1487 4.93317 14.1492 4.93411 14.1497 4.93503C14.2289 5.17886 14.3082 5.42244 14.3876 5.66627C14.3876 5.6665 14.3881 5.66673 14.3888 5.6679C14.6471 5.6679 14.906 5.6679 15.1648 5.6679C15.1653 5.66906 15.1652 5.66929 15.1652 5.66976C15.1652 5.66999 15.1651 5.67046 15.1648 5.67069C14.9559 5.82267 14.747 5.97442 14.537 6.12733C14.5564 6.18863 14.5767 6.24994 14.5967 6.31125C14.6168 6.37279 14.6366 6.43432 14.6567 6.49586C14.6767 6.5574 14.6967 6.61894 14.7166 6.68047C14.7366 6.74178 14.7564 6.80331 14.7746 6.86601C14.5657 6.71427 14.3571 6.56253 14.1476 6.41055C13.9384 6.56253 13.7296 6.71427 13.5208 6.86601C13.5196 6.86555 13.5189 6.86509 13.5182 6.86462Z"
                            fill="#EFEFEF"
                          />
                          <path
                            d="M13.5182 10.376C13.5979 10.1303 13.6776 9.88482 13.7576 9.63873C13.5476 9.48601 13.338 9.3338 13.1289 9.18182C13.1294 9.18066 13.1294 9.18042 13.1297 9.18019C13.1298 9.17995 13.13 9.17972 13.1303 9.17972C13.131 9.17948 13.1317 9.17948 13.1324 9.17948C13.3897 9.17948 13.6471 9.17925 13.9047 9.17925C13.9049 9.17925 13.9053 9.17902 13.9072 9.17832C13.9864 8.93404 14.0662 8.68882 14.1457 8.44359C14.1467 8.44359 14.1468 8.44336 14.1473 8.44336C14.1476 8.44336 14.1481 8.44336 14.1483 8.44359C14.1487 8.44453 14.1492 8.44546 14.1497 8.4464C14.2289 8.69021 14.3082 8.9338 14.3876 9.17762C14.3876 9.17785 14.3881 9.17809 14.3888 9.17925C14.6471 9.17925 14.906 9.17925 15.1648 9.17925C15.1653 9.18042 15.1652 9.18066 15.1652 9.18112C15.1652 9.18135 15.1651 9.18182 15.1648 9.18205C14.9559 9.33403 14.747 9.48582 14.537 9.63873C14.5564 9.69995 14.5767 9.76126 14.5967 9.82257C14.6168 9.88417 14.6366 9.94567 14.6567 10.0073C14.6767 10.0688 14.6967 10.1303 14.7166 10.1919C14.7366 10.2532 14.7564 10.3147 14.7746 10.3774C14.5657 10.2256 14.3571 10.0739 14.1476 9.92195C13.9384 10.0739 13.7296 10.2256 13.5208 10.3774C13.5196 10.3766 13.5189 10.3764 13.5182 10.376Z"
                            fill="#EFEFEF"
                          />
                          <path
                            d="M15.6315 8.61524C15.7112 8.36955 15.7909 8.1241 15.8709 7.87795C15.6609 7.72527 15.4513 7.57305 15.2422 7.42108C15.2427 7.41991 15.2427 7.41968 15.2429 7.41945C15.2431 7.41921 15.2434 7.41898 15.2436 7.41898C15.2443 7.41874 15.245 7.41874 15.2457 7.41874C15.5031 7.41874 15.7604 7.41851 16.018 7.41851C16.0182 7.41851 16.0186 7.41827 16.0205 7.41758C16.0998 7.1733 16.1795 6.92807 16.259 6.68285C16.2599 6.68285 16.2601 6.68262 16.2606 6.68262C16.2609 6.68262 16.2613 6.68262 16.2615 6.68285C16.262 6.68379 16.2625 6.68472 16.2629 6.68565C16.3422 6.92947 16.4215 7.17306 16.501 7.41688C16.501 7.41711 16.5014 7.41735 16.5021 7.41851C16.7604 7.41851 17.0194 7.41851 17.2781 7.41851C17.2785 7.41968 17.2785 7.41991 17.2785 7.42037C17.2785 7.42061 17.2783 7.42108 17.2781 7.42131C17.0693 7.57329 16.8604 7.72503 16.6504 7.87795C16.6697 7.93925 16.69 8.00055 16.71 8.06186C16.7301 8.1234 16.7499 8.18493 16.7699 8.24647C16.79 8.30801 16.8101 8.36955 16.8298 8.43109C16.8499 8.4924 16.8697 8.55393 16.8879 8.61663C16.679 8.46489 16.4704 8.31314 16.2609 8.16116C16.0518 8.31314 15.8429 8.46489 15.6341 8.61663C15.6327 8.61616 15.6322 8.6157 15.6315 8.61524Z"
                            fill="#EFEFEF"
                          />
                          <path
                            d="M15.5533 12.1586C15.633 11.9129 15.7128 11.6675 15.7927 11.4214C15.5827 11.2687 15.3731 11.1164 15.1641 10.9644C15.1645 10.9633 15.1645 10.963 15.1647 10.9629C15.165 10.9626 15.1652 10.9624 15.1655 10.9624C15.1661 10.9621 15.1668 10.9621 15.1675 10.9621C15.4249 10.9621 15.6822 10.9619 15.9398 10.9619C15.94 10.9619 15.9405 10.9617 15.9424 10.961C16.0216 10.7167 16.1013 10.4715 16.1808 10.2263C16.1817 10.2263 16.182 10.2261 16.1825 10.2261C16.1827 10.2261 16.1831 10.2261 16.1834 10.2263C16.1839 10.2272 16.1843 10.2281 16.1847 10.2291C16.264 10.4729 16.3433 10.7165 16.4228 10.9603C16.4228 10.9605 16.4232 10.9608 16.4239 10.9619C16.6822 10.9619 16.9412 10.9619 17.1999 10.9619C17.2004 10.963 17.2004 10.9633 17.2004 10.9638C17.2004 10.964 17.2001 10.9644 17.1999 10.9647C16.9911 11.1167 16.7822 11.2685 16.5722 11.4214C16.5915 11.4827 16.6118 11.5439 16.6318 11.6052C16.6519 11.6668 16.6717 11.7283 16.6917 11.7899C16.7118 11.8514 16.7318 11.9129 16.7516 11.9745C16.7717 12.0358 16.7915 12.0973 16.8097 12.16C16.6009 12.0083 16.3922 11.8566 16.1827 11.7046C15.9736 11.8566 15.7647 12.0083 15.5558 12.16C15.5547 12.1594 15.554 12.1589 15.5533 12.1586Z"
                            fill="#EFEFEF"
                          />
                        </g>
                        <defs>
                          <linearGradient
                            id="paint0_linear_2655_12418"
                            x1="12"
                            y1="0.28125"
                            x2="12"
                            y2="15.418"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="white" />
                            <stop
                              offset="1"
                              stop-color="white"
                              stop-opacity="0"
                            />
                          </linearGradient>
                          <clipPath id="clip0_2655_12418">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>

                      <div>GNUSD</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="font-normal text-[#A4A4A4] text-[14px] mt-1 mb-4">
                1 DAI = 0.9975 gDAI
              </div>
              <GreenButton text="Submit" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
